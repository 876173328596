@import "../../scss/base/_reset.scss";

header.navbar {
  display: flex;
  align-items: center;

  min-height: 60px;
  backdrop-filter: blur(10px);
  padding: 10px 2rem;
  box-shadow: 0 0 10px 2px rgba(0, 0, 0, 0.1);
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 800;
  transition: all 0.5s;

  nav.nav {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    display: flex;
    flex-wrap: nowrap;
    height: min-content;
    padding: 0;
    margin: 0;
    background-color: transparent;

    .theme-and-language {
      display: flex;
      align-items: flex-end;
      gap: 40px;
      button.toggle-lang {
        width: 42px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        outline: none;
        border: none;
        border-radius: 100vw;
        background-color: transparent;
        img {
          height: 15px;
          width: 15px;
        }
      }
      .theme-toggler {
        margin: 0 !important;

      }
    }

    button.navbar-toggler {
      width: 40px;
      height: 40px;
      background-color: transparent !important;
    }

    & .ul-wrapper {
      display: flex;
      align-items: center;

      .item-list {
        display: flex !important;
        flex-wrap: nowrap;
        justify-content: flex-end;
        align-items: center;
        height: 100%;
        text-align: center;
        width: max-content;
        padding: 0;
        margin: 0;
        margin-right: 2rem;

        & li.nav-item {
          font-size: 14px;
          list-style: decimal-leading-zero;
          a.nav-link {
            text-decoration: none;
            font-family: $font-family-monospace;
            font-weight: 500;
            padding: 0.5rem 1rem;
            margin-right: 3rem;
          }
          &::marker {
            padding-left: 0.5rem;
            margin-right: 0.5rem;
          }
        }
        .resume-btn {
          display: flex;
          align-items: center;
          position: inherit;

          .dropdown-menu button.active::after {
            // for theme selector
            content: "\2713";
            margin-left: 10px;
            font-size: larger;
          }
        }
      }
    }
  }
  .dropdown-item:hover {
    color: gray !important;
  }
}

@media (max-width: 767px) {
  header.navbar {
    background-color: #000;
    padding: 0px 2rem !important;
    // border: blue solid 3px !important;
    // flex-direction: column;

    nav.nav {
      display: flex;
      align-items: baseline;

      .logo {
        position: absolute;
        top: 10px;
        left: 15px;
        #logo {
          height: 40px;
        }
      }

      button.navbar-toggler {
        position: absolute;
        top: 10px;
        right: 15px;
      }

      .theme-and-language {
        width: 100%;
        gap: 20px;
        justify-content: flex-end;
        // flex-direction: row-reverse;
        // justify-content: space-between;
        margin-top: 3rem;
        padding-bottom: 1rem;
      }

      .ul-wrapper {
        flex-direction: column;
        width: 100%;
        height: 0px;
        overflow: hidden;

        margin-top: 4rem;

        &.visible {
          height: auto;
          overflow: auto;
        }

        .item-list {
          flex-direction: column;
          display: flex;
          align-items: flex-start;
          margin-left: 27px;
          justify-content: center;
          // margin-top: 1rem;

          li.nav-item {
            font-size: large;
            width: max-content;
            &::marker {
              display: none;
            }
            a.nav-link {
              margin-right: 0;
            }
          }
          & .resume-btn {
            justify-content: space-between;
            align-items: baseline;
            width: 95%;
            .dropdown-menu.show {
              position: absolute !important;
            }
          }
        }
      }
    }
  }
}

// &.collapsed .navbar-toggler-icon-custom {
//   transform: rotate(0deg);
// }

// .navbar-toggler-icon-custom {
//   transform: rotate(90deg);
// }
// &.hidden {
//   transform: translateY(-200%);
//   transition: transform 0.3s ease; // Add transition property
//   // height: 0;
// }

// &.visible {
//   transform: translateY(10);
//   transition: transform 0.3s ease; // Add transition property
//   // height: fit-content;
// }
