// This file contain variable for other SCSS files, dont directly import into jsx
//^ Variables for Colors --------------------------------------------
//! Theme 1 Colors // dark-aqua #0e131a -> c1

$c1: #0e131a;
$c1gray3: #8892b0;
$c1gray2: #a8b2d1;
$c1gray1: #ccd6f6;
$c1whity: #e6f1ff;
$c1AlphaHigh: rgba($c1, 0.85);
$c1AlphaLow: rgba($c1, 0.35);
$c1Light: lighten($c1, 2%);
$c1Lightest: lighten($c1, 20%);
$c1Shadow: darken($c1, 5%);
$c1Tone1: lighten(adjust-hue($c1, 5deg), 3%);
  $x1: $c1;  $varLightness: if(lightness($x1) < 50, 50%, 10%);
  $prec2: adjust-color($x1, $saturation: 100%, $lightness: $varLightness);
$c2: adjust-hue($prec2, -30deg);
$c2AlphaLow: rgba($c2, 0.1);
// $background1Gradient: conic-gradient(from 30deg at -80% 80%, $c1 21deg, rgba($c1Tone1, 90%) 279deg, $c1 1turn);
$background1Gradient: conic-gradient(from 45deg at -35% 65%, #070e15 335deg, rgba(0, 157, 255, 0.03) 359deg, #0a0e14 1turn);
$c2filter: saturate(1.7) sepia(80) opacity(0.8) contrast(0.85) brightness(1.1) hue-rotate(164deg);
$c2filter2: (mix-blend-mode: lighten, filter: hue-rotate(0deg));
$c2filter3: (mix-blend-mode: color-dodge, filter: none);
$c1filterPopup: $c1AlphaHigh; /* (rgba($color: $c1Tone1, $alpha: 0.7)) */

//! Theme 4 Colors  // bright #0a0e06 -> b1
$b1: #ffffff;
$b1gray3: #031a26;
$b1gray2: #2a3041;
$b1gray1: #12394e;
$b1whity: #181a1b;
$b1AlphaHigh: rgba($b1, 0.75);
$b1AlphaLow: rgba($b1, 0.35);
$b1Light: #ecf2f6;
$b1Lightest: lighten($b1, 20%);
$b1Shadow: #0000003d;
$b1Tone1: #e7ebf0;
  $x1: $b1;  $varLightness: if(lightness($x1) < 50, 50%, 10%);
  $prec2: adjust-color($x1, $saturation: 100%, $lightness: $varLightness);
$b2: #006ea9; // #27acf3;
$b2AlphaLow: rgba($b2, 0.1);
$background4Gradient: conic-gradient(from 30deg at -80% 80%, $b1 21deg, rgba($b1Tone1, 90%) 279deg, $b1 1turn);
// $b2filter: saturate(2) sepia(10) opacity(0.7) contrast(0.75) brightness(1) hue-rotate(152deg);
$b2filter: none;
$b2filter2: (mix-blend-mode: exclusion, filter: hue-rotate(167deg) opacity(0.85) brightness(6) contrast(1));
// $b2filter3: (mix-blend-mode: darken, filter: opacity(0.45) contrast(1.4));
$b2filter3: (mix-blend-mode: luminosity, filter: opacity(0.25) contrast(1.2));
$b2filter3Hover: (mix-blend-mode: darken, filter: opacity(0.55) contrast(1.4), transition: all 2s);
$b1filterPopup: $b1AlphaHigh; /* (rgba($color: $b1Tone1, $alpha: 0.9)) */

//^ Variables for Fonts --------------------------------------------
@import url('https://fonts.googleapis.com/css2?family=Chivo+Mono:wght@500&display=swap');

@font-face {
  font-family: 'Chivo Mono Thin';
  src: url('../../../public/fonts/ChivoMono-VariableFont_wght.ttf') format('truetype');
}
@font-face {
  font-family: 'Outfit Thin';
  src: url('../../../public/fonts/outfit-var-font.ttf') format('truetype');
}


$font-family-sans-serif: "Outfit Thin", Calibre, Inter, sans-serif;
$font-family-monospace: "Chivo Mono Thin", "SF Mono", "Fira Code", "Roboto Mono", monospace;
// $font-family-sans-serif: Calibre, Inter, "San Francisco", "SF Pro Text", -apple-system, system-ui, sans-serif;
// $font-family-monospace: "SF Mono", "Fira Code", "Fira Mono", "Roboto Mono", monospace;

$fs-xxs: 12px;
$fs-xs: 13px;
$fs-sm: 14px;
$fs-md: 16px;
$fs-lg: 18px;
$fs-xl: 20px;
$fs-xxl: 22px;
$fs-heading: 32px;

//^ Variables for Spacing --------------------------------------------
$spacing-unit: 1rem;
$spacing-half: calc(#{$spacing-unit} / 2);
$spacing-quarter: calc(#{$spacing-unit} / 4);
$spacing-double: calc(#{$spacing-unit} * 2);
$spacing-triple: calc(#{$spacing-unit} * 3);

//^ Variables for Media Queries --------------------------------------------
$breakpoint-xs: 576px;
$breakpoint-sm: 768px;
$breakpoint-md: 992px;
$breakpoint-lg: 1200px;
$breakpoint-xl: 1400px;

//^ Variables for transitions --------------------------------------------
$transition: all 0.25s cubic-bezier(0.645,0.045,0.355,1);

