@import '../../scss/base/reset';
.back-to-top {
    position: fixed;
    visibility: hidden;
    opacity: 0;
    right: 70px;
    bottom: 30px;
    z-index: 996;
    width: 43px;
    height: 46px;
    border-radius: 50px;
    transition: all 0.4s;
    cursor: pointer;

    &.active {
        visibility: visible;
        opacity: 100;
    }

    & i {
        padding: 5px;
        border-radius: 50%;
        font-size: 32px;
    }
    
}

@media (max-width: 762px) {
    .back-to-top {
        right: 10px;
        bottom: 20px;

        & i {
            
        }
    }
}