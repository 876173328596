@import '../scss/base/reset.scss';

* {
  padding: 0;
  margin: 0;
  transition-timing-function: ease-in;
  transition: 0.5s;
  scroll-behavior: smooth;
}
.App {
  margin: auto;
  font-family: $font-family-sans-serif;
  overflow-x: hidden;
}
.body { ::-webkit-scrollbar{ height: 1px !important; } ::-webkit-scrollbar-thumb{ box-shadow:initial; height: 1px; background: $c1Lightest; } }

.body {
  & > section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: stretch;  
    width: 80vw;
    max-width: 80vw;
    margin: auto;
    min-height: 200px;
  }
  h2 {
    font-size: 42px;
  }
}

p {
  font-size: $fs-lg; 
  line-height: 28px;
  font-weight: 400;
  margin: 20px 0px 0px;
}



button {
  &.btn {
    border-radius: 4px;
    background-color: transparent;
    padding: 1rem 1.2rem;
    min-width: 84px;
    transition: $transition;
    text-wrap: nowrap;
  
    &:hover {
      cursor: pointer;
      text-decoration: none;
    }
  }
  & a {
    text-decoration: none;
    padding: 1rem 0rem;

    &:hover {
      color: $c2;
      text-decoration: none;
    }
  }
}

// BOOTSTRAPP OVERRIDE ---------------------------------

.navbar-toggler {
  border-color: transparent !important;
  &:focus {
    box-shadow: none !important;
    outline: none !important;
  }
}

.row {
  padding-left: 0;
  padding-right: 0;
}

.container {
  padding: 0;
  margin: 0;
}

::-webkit-scrollbar {
  width: 16px;
};

.btn-read-more {
  background-color: transparent;
  border: none;
  width: 100%;
  text-align: end;
  padding-right: 1rem;
  transition: all 1s;
  &:hover {
    text-decoration: underline;
  }
}
.btn-trans {
  border: none;
  background-color: transparent;
}
.pipe-info i {
  color: var(--bs-link-color);
}
@media (max-width: 767px) {
  .App {
    padding: 0 20px;
  }
  .body {        
    padding: 0 0px;
    margin: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    margin: auto;
    
    & > section {    
      width: 99vw !important;
      max-width: 90vw !important;
      
      & .container {
        padding: 0;
        margin: 0;
      }
    }
    .btn-read-more {
      padding-right: 0;
      position: relative;
      right: -25px;
           
  }
  }
}


