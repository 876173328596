@import '../../scss/base/reset';

section.rightpipe {
    // border:blue solid;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 35px;
    height: max-content;

    position: fixed;
    bottom: -40px; 
    right: 40px; 
    & a {
        text-decoration: none;
    
        & p {
            writing-mode: vertical-rl;
            font-size: $fs-xs;
            letter-spacing: 3px;
            font-family: $font-family-monospace;
            &:hover {   
                transform: translateY(-4px);            
            }

            &::after {
                content: "";
                display: inline-flex;
                width: 1px;
                height: 91px;
                margin: 1rem auto;                
            }
        }   
    } 
}

@media (max-width: 767px) {
    section.rightpipe {
        display: none;
    }
}