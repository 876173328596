@import '../../scss/base/reset';

section.leftpipe {
    display: flex;
    flex-direction: column;
    width: 35px;
    align-items: center;

    position: fixed;
    z-index: 5;
    bottom: -170px; 
    left: 40px; 
    transform: translate(-50%, -50%);    

    & ul {
        text-align: center;
        & li {
            margin: 10px auto;
            list-style: none;
            width: 62px;
            & a {
                & svg  {
                    width: 20px;
                    transition: all 0.1s; 
                    overflow: visible;
                }
            }
            &:hover {  
                transform: translateY(-4px);            
            }
        }
        &::after {
            content: "";
            display: block;
            width: 1px;
            height: 110px;
            margin: 0px auto;            
        }
    }
    
}

#pipe-info {
    &.show {
        display: block;
    }
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    width: 500px;
    height: fit-content;
    z-index: 999;
    // background-color: rgba(255, 255, 255, 0.1);
    // background-color: #fff;
    border-radius: 1rem;
    backdrop-filter: blur(10px);
    transition: margin-left 0.5s ease;

    & a {

    }
}

@media (max-width: 767px) {
    section.leftpipe {
        display: none;
    }
    #pipe-info {
        width: 90vw;
        p,a {
            font-size: smaller;
        }
    }
}