@import "../../../scss/base/reset";

.calendly-meeting-wrapper {
  display: flex;
  justify-content: space-between;
  position: relative;
  margin: auto;
  height: auto;
  padding-top: 5rem;
  overflow-x: hidden;

  .meeting-title {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1rem;
  }
  .calendly-meeting-container {
    position: relative;
    width: 30%;
    right: -20px;
    padding: 0;
    padding-left: 3rem;
    background-color: white !important;
    border-radius: 9rem 0 0 9rem;
    overflow: hidden;
  }
}

@media (max-width: 767px) {
  .calendly-meeting-wrapper {
    margin-top: 5rem;
    width: 100% !important;
    right: -6% !important; /* for alignment of frame */

    .meeting-title {
      padding: 10px !important;
      p {
        padding: 0px !important;
      }
    }
    .calendly-meeting-container {
      /* for shadow */
      width: 100%;
      position: relative;
      right: -5%;
    }
  }
}
