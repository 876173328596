@import '../base/_variables.scss';

* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

:export { // For theme picker on navbar
    theme1_bg: $c1;
    theme4_bg: $c1gray1;
    theme1_text: $c2;
    theme4_text: $b1gray3;
}

:root { 
    // scroll initial color
    --c1: #fff;
    --c2: #dedede;
}

@mixin customScrollbar() {
    body {
        overflow: overlay;
        overflow-x: hidden;
    }
    ::-webkit-scrollbar {
        background: -webkit-linear-gradient(var(--c1), var(--c2)); background: linear-gradient(var(--c1), var(--c2));
    };
    ::-webkit-scrollbar-thumb {
        border: 5px solid transparent;
        border-radius: 10px;
        background: -webkit-linear-gradient(transparent,var(--c1)); background: linear-gradient(transparent,var(--c1));
        background-clip: content-box;
        box-shadow: inset 2px 3px 6px rgba(0, 0, 0, 0.9);
    };
    ::-webkit-scrollbar-thumb:hover {
        background: #464646;
    };
}
@include customScrollbar();

.theme1 {  // aqua
    #logo { fill: $c2; }
    // .body { ::-webkit-scrollbar{ height: 7px; width: 1px; } ::-webkit-scrollbar-thumb{ box-shadow:initial; height: 7px; background-color: $c2 !important; } }
    // .body { ::-webkit-scrollbar{ height: 7px; width: 1px; } ::-webkit-scrollbar-thumb{ box-shadow:initial; height: 7px; background: linear-gradient(transparent, $c2) } }
    .co-c1-light { color: $c1Light; }
    .experience .tab-btn { border-left: 2px solid $c2AlphaLow; }
    .experience .tab-btn:hover { color: $c2; background-color: $c2AlphaLow }
    .experience .tab-btn.active { color: $c2; background-color: $c2AlphaLow; border-left: 2px solid $c2; }
    #project-search::placeholder, .c1-lightest { color: $c1Lightest; }
    .brc-c1-alpha-low { border-color: $c1AlphaLow; }
    .brb1-c1-lightest { border-bottom: 1px solid $c1Lightest !important; }
    .brb1-c1-lightest:active, .brb1-c1-lightest:focus { border-bottom: 2px solid $c2AlphaLow !important; }
    .brd-gray1 { border: 1px solid $c1gray1; }
    .co-c2 { color: $c2; }
    .c1-shadow1 { box-shadow: -15px 0px 30px -15px $c1Shadow; }
    .c1-shadow2 { box-shadow: -1px -1px 20px 0px $c1Shadow; }
    .c1-tshadow1 { text-shadow: $c1 1px 1px 1px; }
    .bg-c1-tone-1 { background-color: $c1Tone1; }
    .bg-c1-light { background-color: $c1Light; }
    .bg-c1 { background-color: $c1; }
    .bg-c1-gradient { background-image: $background1Gradient; }
    .bg-c1-lightest { background-image: $c1Lightest; }
    .bg-c1-alpha-high { background-color: $c1AlphaHigh; }
    .bg-c2-alpha-low { background-color: $c2AlphaLow; }
    .back-to-top i:hover { background-color: $c1whity; color: $c1 !important; }
    .co-gray1 { color: $c1gray1 !important; }
    .co-gray2 { color: $c1gray2 !important; }
    .co-gray3 { color: $c1gray3 !important; }
    .co-whity { color: $c1whity; }
    li.tech::before {color: $c2}
    .about .photo .frame { border: 2px solid $c2;}
    .product-info .product-price { color: $c1gray3; }
    .experience .tab-btn { color: $c1gray3; }
    .company>a { color: $c1gray1 !important; }
    .company>a:hover { color: $c1gray3; }
    .leftpipe ul > svg,i,a { color: $c1gray3; }
    .leftpipe ul svg:hover, .leftpipe ul i:hover { color: $c2; }
    .leftpipe>ul::after { background-color: $c1gray3; }
    .rightpipe a p:hover { color: $c2; }
    .rightpipe a p::after { background-color: $c1gray3; }
    .blog .card-content a:hover p { color: $c2; }
    .exp-sentences li { color: $c1gray2; }
    .exp-sentences li::before { color: $c2; }
    h2 { color: $c1gray1; }
    button a { color: $c2 }
    .navbar li a:hover { color: $c2 !important; }
    .navbar li::marker { color: $c2; }
    button.btn { color: $c2; border: $c2 solid 1px; }
    button.btn:hover { color: $c2; border: $c2 solid 1px; background-color: $c2AlphaLow; }
    .card:hover .other-info .card-title > h3 > a { color: $c2; }
    .languages li { color: $c2; background-color: $c2AlphaLow ; font-family: $font-family-monospace;}
    .blog .card-content img { filter: $c2filter; }
    #model-car-canvas { @each $property, $value in $c2filter2 { #{$property}: map-get($c2filter2, $property); }}
    #model-earth-canvas { @each $property, $value in $c2filter3 { #{$property}: map-get($c2filter3, $property); }}
    #tools-and-languages li::marker { color: $c2 }
    #pipe-info { background-color: $c1filterPopup; }
    #fingerprint, #whatsapp-svg { fill: $c2; }
    #themeSwitch:checked { background-color: $c1Lightest; border: 2px solid $c1Lightest; }
    #themeSwitch:checked:focus, #themeSwitch:checked:active { outline: none !important; box-shadow: none !important; }
    .card-container::before { background-image: radial-gradient(circle,transparent, transparent); }
}


.theme4 {  // bright
    #logo { fill: $b2; }
    // .body { ::-webkit-scrollbar{ height: 7px; } ::-webkit-scrollbar-thumb{ box-shadow:initial; height: 7px; background: $b1Lightest !important; } }
    .bg-c1-alpha-high { background-color:  $b1AlphaHigh; }
    .brc-c1-alpha-low { border-color:  $b1AlphaLow; }
    .co-c1-light { color:  $b1Light; }
    .experience .tab-btn { border-left: 2px solid  $b2AlphaLow; }
    .experience .tab-btn:hover { color:  $b2; background-color:  $b2AlphaLow }
    .experience .tab-btn.active { color:  $b2; background-color:  $b2AlphaLow; border-left: 2px solid  $b2;}
    #project-search::placeholder { color:  $b1gray2; }
    #project-search { border-bottom: 1px solid  $b1gray1 !important; }
    .brb1-c1-lightest:active, .brb1-c1-lightest:focus { border-bottom: 2px solid  $b2AlphaLow !important; }
    .co-c2 { color:  $b2; }
    .brd-gray1 { border: 1px solid $b1gray1; }
    .c1-shadow1 { box-shadow: -15px 0px 30px -15px $b1Shadow; }
    .c1-shadow2 { box-shadow: -1px -1px 20px 0px  $b1Shadow; }
    .c1-tshadow1 { text-shadow:  $b1 1px 1px 1px; }
    .bg-c1-tone-1 { background-color:  $b1Tone1; }
    .bg-c1-light { background-color:  $b1Light; }
    .bg-c1 { background-color:  $b1; }
    .bg-c1-gradient { background-image: $background4Gradient; }
    .bg-c2-alpha-low { background-color: $b2AlphaLow; }
    .bg-c1-lightest { background-image: $b1Lightest; }
    .back-to-top i:hover { background-color: $b1whity; color: $b1 !important; }
    .co-gray1 { color: $b1gray1 !important; }
    .co-gray2 { color: $b1gray2 !important; }
    .co-gray3 { color: $b1gray3 !important; }
    .co-whity { color: $b1whity; }    
    li.tech::before {color:  $b2}
    .about .photo .frame { border: 2px solid  $b2;}
    .product-info .product-price { color: $b1gray3; }
    .experience .tab-btn { color: $b1gray3; }
    .company>a { color: $b1gray1 !important; }
    .company>a:hover { color: $b1gray3; }
    .leftpipe ul > svg,i,a { color: $b1gray3; }
    .leftpipe ul > svg, .leftpipe ul > i, .leftpipe ul > a:hover { color:  $b2; }
    .leftpipe>ul::after { background-color: $b1gray3; }
    .rightpipe a p:hover { color:  $b2; }
    .rightpipe a p::after { background-color: $b1gray3; }
    .blog .card-content a:hover p { color:  $b2; }
    .exp-sentences li { color: $b1gray2; }
    .exp-sentences li::before { color:  $b2; }
    h2 { color: $b1gray1; }
    button a { color: $b2 }
    .navbar a:hover { color: $b2 !important; }
    .navbar li::marker { color: $b2; }
    button.btn { color: $b2; border: $b2 solid 1px; }
    button.btn:hover { color: $b2; border: $b2 solid 1px; background-color: $b2AlphaLow; }
    .card:hover .other-info .card-title > h3 > a { color: $b2; }
    p { color: $b1gray3; }
    section p { color: $b1gray3; }
    .languages li { color: $b2; background-color: $b2AlphaLow ; font-family: $font-family-monospace;}
    .languages li { color: $b1gray2; }
    .blog .card-content img { filter: $b2filter; }
    #model-car-canvas { @each $property, $value in $b2filter2 { #{$property}: map-get($b2filter2, $property); }}
    #model-earth-canvas { @each $property, $value in $b2filter3 { #{$property}: map-get($b2filter3, $property); }}
    #model-earth-canvas:hover { @each $property, $value in $b2filter3Hover { #{$property}: map-get($b2filter3Hover, $property); }}
    #pipe-info { background-color: $b1filterPopup; }
    .circle { background-color: rgba($color: $b1Shadow, $alpha: 0.1) !important;}
    #fingerprint, #whatsapp-svg { fill: $b2; }
    #themeSwitch:focus, #themeSwitch:active { outline: none !important; box-shadow: none !important; }
    #themeSwitch { background-color: $b2AlphaLow; border: 1px solid $b1Shadow; }
    .card-container::before { background-image: radial-gradient(circle, #91a3ca86, rgba(255, 255, 255, 0.412)); }
}
