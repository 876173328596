@import '../../scss/base/variables';

@keyframes bg-blur {
  0% {
    -webkit-backdrop-filter: blur(20px);
    backdrop-filter: blur(20px);
    background-color: rgba(255, 255, 255, 0.2);
  }
  100% {
    -webkit-backdrop-filter: blur(0px);
    backdrop-filter: blur(0px);
    background-color: rgba(255, 255, 255, 0);
  }
}

  @keyframes mozarillani {
    0% {
      stroke-dashoffset: 1390;
      stroke: $c1;
      transform: scale(0) rotate(55Deg);
    }
    80% {
      stroke-dashoffset: 0;
      stroke: $b1;
      fill: $b2;
    }
    82% {
      fill: $b2;
      stroke: $b1;
      stroke-dashoffset: 0;
      transform: scale(2.6) rotate(0);
    }    
    100% {
      fill: $b2;
      stroke: $b1;
      stroke-dashoffset: 0;
      transform: scale(1.8);
    }
  }
  
  .opener-animation {
    position: fixed;
    width: 100vw;
    height: 100%;
    z-index: 999;
    display: flex;
    justify-content: center;
    align-items: center;
    // background: linear-gradient(to right, $c1Shadow, $c1);
      // backdrop-filter: blur(10px);
    animation: 2s linear bg-blur;
    
    #logo  {
      width: 150px;
      stroke-width: 10px;
      fill: $c1;
      stroke-dasharray: 1390;
      stroke-dashoffset: 1390;
      animation: 2s cubic-bezier(0.1, 1.3, 0.5, 1.6) forwards mozarillani;
    }

  }